import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet'; // Step 1: Import Helmet

import './LandingPage.css';

function LandingPage() {
  const content = [
    { title: 'Property Management', description: 'Coming this fall', smallImg: '/property-management-occupancy.jpg', largeImg: '/propert-management-screen.png' },
    { title: 'Reservations Management', description: 'Coming this fall', smallImg: '', largeImg: '/ReservationsManagement.png' },
    { title: 'Channel Manager', description: 'Coming this fall', smallImg: '/channel-manager-small.png', largeImg: '/ChannelManager.png' },
    { title: 'Booking Engine', description: 'Coming this fall', smallImg: '', largeImg: '/booking-engine.png' },
    { title: 'Accounting & Finance', description: 'Coming this fall', smallImg: '', largeImg: '/accounting.png' },
  ];

  const [currentContentIndex, setCurrentContentIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentContentIndex((prevIndex) => (prevIndex + 1) % content.length);
    }, 5000); // Change content every 5 seconds

    return () => clearInterval(interval);
  }, [content.length]);

  const currentContent = content[currentContentIndex];


  return (
    <div>
      <Helmet>
        <title>Prostay - All In One Property Management System</title>
        <meta name="description" content="Cutting-edge Property Management System designed to revolutionize the way hotels, and resorts from boutique to large-scale, operate and serve their guests." />
        {/* Add more meta tags as needed */}
      </Helmet>
      <div className="landing-page">

        <div className="title-section">
          <div key={currentContent.title} className={`title-fade-in row w-100 justify-content-center`} >
            <h1 style={{ width: 'fit-content' }}>
              {currentContent.title}
            </h1>
            <img className="stars" src="/blue-stars.png" alt="stars" />
          </div>
          <div className="row justify-content-center align-items-center">
            <div className="fixed-title" style={{ width: 'fit-content' }}>With Prostay</div>
            {currentContent.smallImg && <img key={currentContent.smallImg} src={currentContent.smallImg} alt={currentContent.title}
              className={currentContent.title === "Channel Manager" ? "large-img small-img-fade-in " : " small-img-fade-in small-img"} />}
          </div>
          <p>{currentContent.description}</p>
        </div>
        {currentContent.largeImg && <div className="d-flex"><img key={currentContent.largeImg} className={`large-img-fade-in anchor-bottom-img`} src={currentContent.largeImg} alt={`${currentContent.title} Screen`} /></div>}
      </div>
    </div>

  );
}

export default LandingPage;
